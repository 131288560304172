import React, { useRef } from "react"
import { motion } from 'framer-motion'
import { socialMedia } from '../config'

const Project = ({ content }) => {
    const titleRef = useRef()

    return(
        <section id="projects" className="flex flex-row mx-auto w-1/2 h-screen relative">
        <div className="w-1/2">
            
        </div>
        <div className="w-1/2 my-auto">
        <h3 ref={titleRef} id="project-title" className="text-3xl font-bold mb-12">[Projects]</h3>
        {content.map((project, key) => {
            const { frontmatter} = project.node

            return(
                <div key={frontmatter.position}>
                    <motion.div 
                    whileHover={{
                    scale: [1, 1.2, 1.2, 1, 1],
                    rotate: [0, 0, -5, 10, 0],}}
                    className="purplify">
                        <a target="_blank" rel="noopener noreferrer" href={frontmatter.external} className="text-xl font-bold uppercase" >
                        {frontmatter.title}
                        </a>
                        <br></br>
                        
                        <a target="_blank" rel="noopener noreferrer" href={frontmatter.external} className="text-ml mb-8">
                        {frontmatter.director}
                        </a>
                        <br></br>
                        <br></br>
                    </motion.div>
                </div>
                )
            })}
            <div className="pt-5 text-right text-sm font-light">
                <motion.p
                whileHover={{
                    scale: 1.2}}>
                    ..More @ <a target="_blank" rel="noopener noreferrer" className="hover:underline" href={socialMedia.url}><b>{socialMedia.name}</b></a></motion.p>
                {console.log(socialMedia.url)}
            </div>
            </div>
        </section>
    )
}

export default Project 