import React from "react"
import { motion } from 'framer-motion'

const Contact = () => {

  return (
      

<div id="contact" className="flex h-screen relative">  
<section  className="m-auto w-1/3">

<form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
  <input type="hidden" name="bot-field" />
  <input type="hidden" name="form-name" value="contact" />
  <h1 className="my-10 text-4xl font-bold">Contact</h1>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label htmlFor="name" className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2">Name</label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
            type="text" name="name" id="name" />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
    <label className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2"
          htmlFor="email">Email</label>
    <input className="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
          type="text" name="email" id="email" />
    </div>
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
    <label className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2"
          htmlFor="message">Message</label>
    <textarea className="no-resize appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
    name="message" id="message" />
    </div>
  </div>
  <div className="md:flex md:items-center">
  <div className="md:w-1/3">
    <motion.button whileHover={{
                scale: [1, 1.2, 1.2, 1,],
                rotate: [0, 2, -2, 0],}} className="text-xl btn-blue text-white font-bold py-2 px-4 border-b-4 border-indigo-500 purplify hover:border-purple-400 rounded-full py-2 px-6 mt-6"
            type="submit">
      Send 
    </motion.button>
    </div>
    </div>
</form>

    </section>
    </div>  
  )
}

export default Contact

