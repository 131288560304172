import React from "react"
import { Link } from "gatsby"
import { navLinks } from "../config/index"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion } from 'framer-motion'
import ScrollIntoView from 'react-scroll-into-view'

const Hero = ({ content }) => {
    const { button } = navLinks
    const { frontmatter, body } = content[0].node

    const variants ={
        hidden: {
            opacity: 0,
            x: -10,
        },
        display: {
            opacity: 1,
            x: 0,
        },
    }

    return (
        <div id="about" className="flex items-center shadow-inner h-screen relative">
            
            <section className="m-auto w-1/2">
            <div className="flex justify-between">
            <div>
            <motion.h2 
            className="font-bold text-4xl w-4/5"
            variants={variants}
            initial="hidden"
            animate="display"
            transition={{ delay: 0.8 }}>
            {frontmatter.title}
            </motion.h2>

            <motion.div 
            className=" text-xl"
            variants={variants}
            initial="hidden"
            animate="display"
            transition={{ delay: 1 }}>
            <MDXRenderer>{body}</MDXRenderer>
            </motion.div>

            <ScrollIntoView selector="#projects" smooth="true">
                <motion.button 
                className="focus:outline-none mt-12 btn-blue text-xl text-white font-bold py-2 px-4 border-b-4 border-indigo-500 purplify hover:border-purple-400 mt-6 rounded-full py-2 px-4"
                variants={variants}
                initial="hidden"
                animate="display"
                transition={{ delay: 1.2 }}>
                View Projects
                </motion.button>
                </ScrollIntoView>
            </div>

            </div>
            </section>
        </div>

        
    )
}

export default Hero