import { Link } from "gatsby"
import React from "react"
import ScrollIntoView from 'react-scroll-into-view'
import { siteShortTitle } from '../config'

import { motion } from 'framer-motion'

const headerVariants = {
    hidden: {
      opacity: 0,
      y: -10,
    },
    display: {
      opacity: 1,
      y: 0,
    }
    

}


const Header = () => {
  
  return(
    <motion.header 
    className="fixed flex items-center justify-between py-6 px-12"
    variants={headerVariants}
    initial="hidden"
    animate="display"
    
    >
      <Link to="/" aria-label="home">
        <motion.h1 whileHover={{
                scale: [1, 1.2, 1,],
                rotate: [0, -2, 0],}} className="logo text-2xl uppercase font-hairline">
          {siteShortTitle}
        </motion.h1>
      </Link>
      <nav className="flex items-center">

      <ScrollIntoView selector="#about">
      <motion.button 
      whileHover={{rotate: [0, -20, 0]}}
      className="text-2xl px-3 py-2 purpletext focus:outline-none">
      About</motion.button>
      </ScrollIntoView>

      <ScrollIntoView selector="#projects" smooth="true">
      <motion.button 
      whileHover={{rotate: [0, 10, 0]}}
      className="text-2xl px-3 py-2 purpletext focus:outline-none">
      Projects</motion.button>
      </ScrollIntoView>

      <ScrollIntoView selector="#services" alignToTop="true" smooth="true">
      <motion.button 
      whileHover={{rotate: [0, -10, 0]}}
      className="text-2xl px-3 py-2 purpletext focus:outline-none"> 
      Services</motion.button>
      </ScrollIntoView>

      <ScrollIntoView selector="#contact">
      <motion.button 
      whileHover={{rotate: [0, 20, 0]}}
      className="text-2xl px-3 py-2 purpletext focus:outline-none"> 
      
      Contact</motion.button>
      </ScrollIntoView>
      </nav>
    </motion.header>
  )

}


export default Header
