import React from "react"
import { Link } from "gatsby"

import { siteShortTitle } from "../config/index"

const Footer = () => {
  return (
    <footer className="flex items-center justify-between btn-blue py-3 px-12 purplify text-right">
      <Link to="/" aria-label="home">
        <h1 className="text-xl font-bold text-white">&#169; {new Date().getFullYear()} {siteShortTitle}</h1>
      </Link>
    </footer>
  )
}

export default Footer