import React from "react"
import Img from 'gatsby-image'
import { motion } from 'framer-motion'

import { variants } from './Hero'
import ScrollIntoView from 'react-scroll-into-view'


const Services = ({ content }) => {
    const { frontmatter } = content[0].node


    return (
      <div id="services" className="flex h-screen relative">   
        <section  className="m-auto w-3/5 ">
      <div className=" font-light flex space-x-4">
        <div className="w-1/2 my-auto">
        <div className="w-3/5 mx-auto">
        <h3 className="text-3xl font-bold mb-6">[{frontmatter.title}]</h3>
                <div className="py-3 text-lg">
                <p>Research</p>
                <p>Rights Clearance</p>
                <p>Licensing</p>
                <p>Consulting</p>
                <p>Database Structures</p>
                </div>

                <ScrollIntoView selector="#contact" smooth="true">
                <motion.button 
                className="focus:outline-none text-xl btn-blue  text-white font-bold py-2 px-4 border-b-4 border-indigo-500 purplify hover:border-purple-400 rounded-full py-2 px-4 mt-6"
                variants={variants}
                initial="hidden"
                animate="display"
                transition={{ delay: 1.2 }}
                >
                Get in touch
                </motion.button>
                </ScrollIntoView>
                </div>
        </div>
        <div className="w-1/2">
        <figure>
            <Img fluid={frontmatter.image.childImageSharp.fluid} className="rounded-full py-2 px-4" />
          </figure>
        </div>
      </div>
    </section>
    </div>
    )
}

export default Services 