// config/index.js

module.exports = {
    author: "Soyoon Choi",
    siteTitle: "Mona El Bira - Portfolio",
    siteShortTitle: "Mona El-Bira | Visual Research",
    siteDescription:
      "personal portfolio. Mona is a visual & archive researcher based in Berlin, Germany.",
    siteLanguage: "en_UK",
    socialMedia: 
      {
        name: "Crew United",
        url: "https://www.crew-united.com/de/Mona-El-Bira_502255.html#!searchterm=mona%20el-bira",
      },
    
    navLinks: {
      menu: [
        {
          name: "Services",
          url: "/#services",
        },
        {
          name: "Contact",
          url: "/contact",
        },
      ],
      button:  {
        name: "View Projects",
        url: "/#project-title",
      },
      
    },
  }