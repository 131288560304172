import React from "react"
import '../styles/site.scss'
import Layout from "../components/Layout"
import { graphql } from "gatsby"


//import components

import Hero from '../components/Hero'
import About from '../components/Services'
import Project from '../components/Project'
import Contact from '../components/Contact'


export default ({ data }) => {
  return (
    <Layout>
      <Hero content={data.hero.edges} />
      <Project content={data.project.edges} />
      <About content={data.about.edges} />
      <Contact/>
    </Layout>
  )
}


export const pageQuery = graphql`
  {
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/hero/" } }) {
      edges {
        node {
          body
          frontmatter {
            intro
            title
          }
        }
      }
    }
    about: allMdx(filter: { fileAbsolutePath: { regex: "/about/" } }) {
      edges {
          node {
              body
              frontmatter {
                  title
                                          
                  image {
                      childImageSharp {
                          fluid(maxWidth: 800) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
      }
  }
  project: allMdx(filter: {
                      fileAbsolutePath: {regex: "/project/"}
                      frontmatter: { visible: { eq: "true" } }
} 
    sort: { fields: [frontmatter___position], order: ASC })
  {
    edges {
      node {
        body
        frontmatter {
          title
          visible
          director
          position  
          external
        }
      }
    }
  }
  }

`